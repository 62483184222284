import React from 'react'
import { Link } from 'react-router-dom'

const RegisterForm = (props) => {
  return (
    <div class="model">
    <div id="login-modal">
        <div class="login-form default-form">
            <div class="form-inner">
                <h3>Create a Free Talenda Account</h3>

                <form method="post" action="/">
                    {/* <div class="form-group">
                        <div class="btn-box row">
                            <div class="col-lg-6 col-md-12">
                                <a href="#" class="theme-btn btn-style-seven"><i class="la la-user"></i> Candidate </a>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <a href="#" class="theme-btn btn-style-four"><i class="la la-briefcase"></i> Employer </a>
                            </div>
                        </div>
                    </div> */}

                    <div class="form-group">
                        <label>Email Address</label>
                        <input type="email" name="email" placeholder="Email" required />
                    </div>
                    <div class="form-group">
                        <label>Mobile No.</label>
                        <input type="text" name="mobileNo" placeholder="Mobile Number" required />
                    </div>
                    
                    <div class="form-group">
                        <label>Password</label>
                        <input id="password-field" type="password" name="password" value="" placeholder="Password" />
                    </div>

                    <div class="form-group">
                        <button class="theme-btn btn-style-one " type="submit" name="Register">Register</button>
                    </div>
                </form>
                <div className="bottom-box">
                    <div className="text">Already have an account? <Link onClick={() => props.showLogin(true)} className="call-modal signup">Login</Link></div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default RegisterForm