import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap'
import Logo from '../../../assets/logo_image.png';
import LoginForm from '../../../components/Common/LoginForm'
import './Navigation.css';
import RegisterForm from '../../../components/Common/RegisterForm';

const Navigation = () => {
    const [navbar, setNavbar] = useState('unScrolled');
    const [show, setShow] = useState(false);
    const [login, setLogin] = useState(true);

    const changeNavbarBackground = () => {
        if (window.scrollY >= 300) {
            setNavbar('scrolled');
        }
        else if (window.scrollY <=5){
            setNavbar('unScrolled');
        }
    };

    window.addEventListener('scroll', changeNavbarBackground);

    const toggleModal = () => {
        setShow(!show);
    }

    const showLogin = (props) => {
        setLogin(props)
        // console.log(props);
    }
  return (
        <header className={`main-header ${navbar}`}>
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="/">
                        <div className="logo-box">
                        <div className="logo">
                            <img src={Logo} width="60px" alt="" title="Talenda Hire" />
                            <span className="ml-2 h4"> Talenda Hire</span>
                            </div>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="far fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" href="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" href="/">Link</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" href="/">Action</Link></li>
                                    <li><Link className="dropdown-item" href="/">Another action</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" href="/">Something else here</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link disabled" href="/" tabindex="-1" aria-disabled="true">Disabled</Link>
                            </li>
                        </ul> */}
                        <div className="outer-box">
                            <div className="btn-box">
                                <Link to="/interview" className="theme-btn btn-style-eight">Record Interview</Link>
                                <Link to='/' onClick={toggleModal} className="theme-btn btn-style-nine call-modal">Login / Register</Link>
                                {/* <Button href="#" className="theme-btn btn-style-ten">Job Post</Button> */}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <Modal centered aria-labelledby="contained-modal-title-vcenter" show={show} onHide={toggleModal}>
                <Modal.Header closeButton>
                
                </Modal.Header>
                <Modal.Body>
                {login ? <LoginForm showLogin={showLogin}/> : <RegisterForm showLogin={showLogin}/> }
                </Modal.Body>
                
            </Modal>
        </header>
  );
}

export default Navigation;