import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/logo_image.png';
import Company6 from '../../assets/resource/company-6.png';
import './CandidateLayout.css';

function CandidateLayout(props) {
    const [navbar, setNavbar] = useState('dashboardUnScrolled');
    const [sidebar, setSidebar] = useState('');

    useEffect(() => {
        function handleSidebarClose() {
            if(sidebar === 'user-sidebar-active'){
                setSidebar('')
            }
        }
        window.addEventListener("click", handleSidebarClose)
        return () => {
            window.removeEventListener("click", handleSidebarClose)
        }
    }, [sidebar])


    const toggleSideBar = () => {
        if(sidebar === ''){
            setSidebar('user-sidebar-active')
        }else if(sidebar === 'user-sidebar-active'){
            setSidebar('')
        }
    }



    const changeNavbarBackground = () => {
        if (window.scrollY >= 300) {
            setNavbar('dashboardScrolled');
        }
        else if (window.scrollY <=5){
            setNavbar('dashboardUnScrolled');
        }
    };

    window.addEventListener('scroll', changeNavbarBackground);

    return (
        <>
        <div className={`mm-wrapper ${sidebar}`}>

            <div className="page-wrapper dashboard">
                <span className="header-span"></span> 
                <header className={`main-header ${navbar} header-shaddow`}>
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg">
                            <Link className="navbar-brand" to="/">
                                <div className="logo-box">
                                    <div className="logo">
                                        <img src={Logo} width="60px" alt="" title="Talenda Hire" />
                                        <span className="ml-2 h4"> Talenda Hire</span>
                                    </div>
                                </div>
                            </Link>
                            <div className='nav-outer clearfix'>
                                <div className='outer-box' style={{paddingRight: "8px", paddingTop: "8px"}}>
                                    <button type='button' onClick={() => toggleSideBar()}>
                                     <i className="fas fa-chevron-circle-right" style={{fontSize: "25px"}}></i>
                                    </button>
                                </div>
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="far fa-bars"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link active" aria-current="page" href="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" href="/">Link</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Dropdown
                                        </Link>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><Link className="dropdown-item" href="/">Action</Link></li>
                                            <li><Link className="dropdown-item" href="/">Another action</Link></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><Link className="dropdown-item" href="/">Something else here</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link disabled" href="/" tabindex="-1" aria-disabled="true">Disabled</Link>
                                    </li>
                                </ul> */}
                                <div className="outer-box">
                                    <button className="menu-btn">
                                        <span className="count">1</span>
                                        <span className="icon fal fa-heart"></span>
                                    </button>

                                    <button className="menu-btn">
                                        <span className="icon fal fa-bell"></span>
                                    </button>

                                    <div className="dropdown dashboard-option">
                                        <a className="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"> 
                                            <img src={Company6} alt="avatar" className="thumb" /> 
                                            <span className="name">My Account</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
                <div className="sidebar-backdrop"></div>

                <div className="user-sidebar" id='userSidebar'>

                    <div className="sidebar-inner">
                        <ul className="navigation">
                            <li className="active"><NavLink to="/dashboard"> <i className="fal fa-home-alt"></i> Dashboard</NavLink></li>
                            <li><NavLink to="/profile"><i className="fal fa-user-alt"></i>My Profile</NavLink></li>
                            <li><NavLink to="/resume"><i className="fal fa-file-alt"></i>My Resume</NavLink></li>
                            <li><NavLink to="/appliedjobs"><i className="fal fa-briefcase"></i> Applied Jobs </NavLink></li>
                            <li><NavLink to="/jobalerts"><i className="fal fa-bell"></i>Job Alerts</NavLink></li>
                            <li><NavLink to="/shortlistedjobs"><i className="fal fa-bookmark"></i>Shortlisted Jobs</NavLink></li>
                            <li><NavLink to="/cvmanager"><i className="fal fa-file-alt"></i> CV manager</NavLink></li>
                            <li><NavLink to="/packages"><i className="fal fa-box-alt"></i>Packages</NavLink></li>
                            <li><NavLink to="/messages"><i className="fal fa-comment-alt-lines"></i>Messages</NavLink></li>
                            <li><NavLink to="/changepassword"><i className="fal fa-lock"></i>Change Password</NavLink></li>
                            <li><NavLink to="/viewprofile"><i className="fal fa-user-alt"></i>View Profile</NavLink></li>
                            <li><NavLink to="/"><i className="fal fa-sign-out"></i>Logout</NavLink></li>
                            <li><NavLink to="/"><i className="fal fa-trash-alt"></i>Delete Profile</NavLink></li>
                        </ul>

                    </div>
                </div>
                <div>
                    {props.children}
                </div>
                <div className="copyright-text">
                    <p>© 2021 Talenda Hire. All Right Reserved.</p>
                </div>

            </div>
            </div>
        </>
    )
}

export default CandidateLayout;
