import React from 'react'
import { Link } from 'react-router-dom'

const LoginForm = (props) => {
  return (
    <div className="model">
    <div id="login-modal">
        <div className="login-form default-form">
            <div className="form-inner">
                <h3>Login to Talenda</h3>
                <form method="post" action="/">
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" name="email" placeholder="Email" required />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input id="password-field" type="password" name="password" value="" placeholder="Password"/>
                    </div>

                    <div className="form-group">
                        <div className="field-outer">
                            <div className="input-group checkboxes square">
                                <input type="checkbox" name="remember-me" value="" id="remember"/>
                                <label for="remember" className="remember"><span className="custom-checkbox"></span> Remember me</label>
                            </div>
                            <a href="#" className="pwd">Forgot password?</a>
                        </div>
                    </div>

                    <div className="form-group">
                        <button className="theme-btn btn-style-one" type="submit" name="log-in">Log In</button>
                    </div>
                </form>

                <div className="bottom-box">
                    <div className="text">Don't have an account? <Link onClick={() => props.showLogin(false)} className="call-modal signup">Signup</Link></div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default LoginForm