import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/logo_image.png';
import './Footer.css';

function Footer() {
    return (
        <div>
            <div className="container">
            <footer className="main-footer">
                <div className="auto-container">
                    <div className="widgets-section wow fadeInUp">
                        <div className="row">
                            <div className="big-column col-xl-4 col-lg-3 col-md-12">
                                <div className="footer-column about-widget">
                                    <div className="logo">
                                        <Link to="/">
                                            <div>
                                                <img src={Logo} width="60px" alt="" title="Talenda Hire" />
                                                <span className="h4">Talenda Hire</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <p className="phone-num"><span>Call us </span><Link to="#">123 456 7899</Link></p>
                                    <p className="address">111 Sheikh Hamza Rd - Downtown Dubai - Dubai <br></br> 3051 United Arab Emirates. <br></br><Link to="mailto:support@talendahire.com" className="email">support@talendahire.com</Link></p>
                                </div>
                            </div>

                            <div className="big-column col-xl-8 col-lg-9 col-md-12">
                                <div className="row">
                                    <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <h4 className="widget-title">For Candidates</h4>
                                            <div className="widget-content">
                                                <ul className="list">
                                                    <li><Link to="/appliedjobs">Browse Jobs</Link></li>
                                                    <li><Link to="/shortlistedjobs">Browse Categories</Link></li>
                                                    <li><Link to="/dashboard">Candidate Dashboard</Link></li>
                                                    <li><Link to="/jobalerts">Job Alerts</Link></li>
                                                    <li><Link to="/cv_edit">CV Edit</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

            
                                    <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <h4 className="widget-title">For Employers</h4>
                                            <div className="widget-content">
                                                <ul className="list">
                                                    <li><Link to="/resume_alerts">Browse Candidates</Link></li>
                                                    <li><Link to="/employee_dashboard">Employer Dashboard</Link></li>
                                                    <li><Link to="/postjob">Add Job</Link></li>
                                                    <li><Link to="/employee_packages">Job Packages</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <h4 className="widget-title">About Us</h4>
                                            <div className="widget-content">
                                                <ul className="list">
                                                    <li><Link to="#">Job Page</Link></li>
                                                    <li><Link to="#">Job Page Alternative</Link></li>
                                                    <li><Link to="#">Resume Page</Link></li>
                                                    <li><Link to="#">Blog</Link></li>
                                                    <li><Link to="#">Contact</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <h4 className="widget-title">Helpful Resources</h4>
                                            <div className="widget-content">
                                                <ul className="list">
                                                    <li><Link to="#">Site Map</Link></li>
                                                    <li><Link to="#">Terms of Use</Link></li>
                                                    <li><Link to="#">Privacy Center</Link></li>
                                                    <li><Link to="#">Security Center</Link></li>
                                                    <li><Link to="#">Accessibility Center</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="outer-box row">
                            <div className="col-12 col-lg-6">
                                <div className="copyright-text">© 2021 <Link to="#">Talenda Hire</Link>. All Right Reserved.</div>                                
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="social-links">
                                    <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                                    <Link to="#"><i className="fab fa-twitter"></i></Link>
                                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="scroll-to-top scroll-to-target" data-target="html"><span className="fal fa-angle-up"></span></div>
            </footer>
            </div>
        </div>
    )
}

export default Footer;
