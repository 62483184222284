import React from 'react';
import ReactDOM from 'react-dom';
import Logo from './assets/favicon.png';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.Suspense fallback={
      <div className="spinner-centered">
        <img src={Logo} alt="Loader" />
        <h6>Loading</h6>
      </div>
    }>
    <App />
  </React.Suspense>,
  document.getElementById('root')
);

reportWebVitals();
