import React from 'react';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import PageNotFound from './components/UI/ProtectedRoutes/PageNotFound';
import Layout from './core/Layout';
import CandidateLayout from './core/CandidateLayout';
import EmployeeLayout from './core/EmployeeLayout';

const Home = React.lazy(() => import('./pages/Home'));
const JobList = React.lazy(() => import('./pages/JobList'));
const Dashboard = React.lazy(() => import('./pages/UserAccount/Dashboard'));
const Profile = React.lazy(() => import('./pages/UserAccount/Profile'));
const Resume = React.lazy(() => import('./pages/UserAccount/Resume'));
const AppliedJobs = React.lazy(() => import('./pages/UserAccount/AppliedJobs'));
const ShortlistedJobs = React.lazy(() => import('./pages/UserAccount/ShortlistedJobs'));
const JobAlerts = React.lazy(() => import('./pages/UserAccount/JobAlerts'));
const ChangePassword = React.lazy(() => import('./pages/UserAccount/ChangePassword'));
const Packages = React.lazy(() => import('./pages/UserAccount/Packages'));
const CVManager = React.lazy(() => import('./pages/UserAccount/CvManager'));
const Messages = React.lazy(() => import('./pages/UserAccount/Messages'));
const ViewProfile = React.lazy(() => import('./pages/UserAccount/Profile'));
const CompanyProfile = React.lazy(() => import('./pages/UserAccount/CompanyProfile'));
const EmployeeDashboard = React.lazy(() => import('./pages/UserAccount/EmployeeDashboard'));
const ResumeAlerts = React.lazy(() => import('./pages/UserAccount/ResumeAlerts'));
const PostJob = React.lazy(() => import('./pages/UserAccount/PostJob'));
const ManageJobs = React.lazy(() => import('./pages/UserAccount/ManageJob'));
const Applicants = React.lazy(() => import('./pages/UserAccount/Applicants'));
const ShortlistedResume = React.lazy(() => import('./pages/UserAccount/ShortlistedResume'));
const Interview = React.lazy(() => import('./components/Interview/Interview'));
const Quiz = React.lazy(() => import('./components/Interview/Quiz'));
const CvEdit = React.lazy(() => import('./pages/UserAccount/CvManager/CvEdit'));

const Router = () => {

    return (
            <BrowserRouter> 
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={(<Home />)} />
                  <Route path='/interview' element={<Interview />} />
                  <Route path='/quiz' element={<Quiz />} />
                  <Route path='/joblist' element={<JobList />} />
                </Route>
                <Route path='/dashboard' element={<CandidateLayout><Dashboard /></CandidateLayout>} />
                <Route path='/profile' element={<CandidateLayout><Profile /></CandidateLayout>} />
                <Route path='/resume' element={<CandidateLayout><Resume /></CandidateLayout>} />
                <Route path='/cv_edit' element={<CandidateLayout><CvEdit /></CandidateLayout>} />
                <Route path='/appliedjobs' element={<CandidateLayout><AppliedJobs /></CandidateLayout>} />
                <Route path='/shortlistedjobs' element={<CandidateLayout><ShortlistedJobs /></CandidateLayout>} />
                <Route path='/jobalerts' element={<CandidateLayout><JobAlerts /></CandidateLayout>} />
                <Route path='/changepassword' element={<CandidateLayout><ChangePassword /></CandidateLayout>} />
                <Route path='/cvmanager' element={<CandidateLayout><CVManager /></CandidateLayout>} />
                <Route path='/messages' element={<CandidateLayout><Messages /></CandidateLayout>} />
                <Route path='/viewprofile' element={<CandidateLayout><ViewProfile /></CandidateLayout>} />
                <Route path='/employee_messages' element={<EmployeeLayout><Messages /></EmployeeLayout>} />
                <Route path='/employee_packages' element={<EmployeeLayout><Packages /></EmployeeLayout>} />
                <Route path='/company_profile' element={<EmployeeLayout><CompanyProfile /></EmployeeLayout>} />
                <Route path='/employee_viewprofile' element={<EmployeeLayout><CompanyProfile /></EmployeeLayout>} />
                <Route path='/employee_dashboard' element={<EmployeeLayout><EmployeeDashboard /></EmployeeLayout>} />
                <Route path='/resume_alerts' element={<EmployeeLayout><ResumeAlerts /></EmployeeLayout>} />
                <Route path='/employee_changepassword' element={<EmployeeLayout><ChangePassword /></EmployeeLayout>} />
                <Route path='/postjob' element={<EmployeeLayout><PostJob /></EmployeeLayout>} />
                <Route path='/managejobs' element={<EmployeeLayout><ManageJobs /></EmployeeLayout>} />
                <Route path='/applicants' element={<EmployeeLayout><Applicants /></EmployeeLayout>} />
                <Route path='/shortlistedresume' element={<EmployeeLayout><ShortlistedResume /></EmployeeLayout>} />
                <Route path='*' element={<PageNotFound />} />
              </Routes>
            </BrowserRouter>  
     );
}
 
export default Router;