import React from "react";
import Router from "./Router";
import './App.css';

const App = () => {

  return <Router />;
}
  
export default App; 
