import React from "react";
import { Link } from 'react-router-dom';

const PageNotFound = () => {

    return (
        <React.Fragment>
            <div className="container text-center mt-5 vh-100">
                <div className="page-not-found-text">
                    <h1 className="text-muted">*404*</h1>
                    <h3 className="mt-3 text-muted">File not found</h3>
                    <h5 className="mt-3 text-muted">This is not the web page you are looking for.</h5>
                    <p className="mt-3 text-muted">The site configured at this address does not contain the requested file.</p>
                    <Link to="/">
                        <button className="btn btn-default border-secondary mt-3 text-muted">
                            Back to Home
                        </button>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PageNotFound;